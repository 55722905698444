import styled from "styled-components";
import { CSSVars } from "../../../styles/styles.config";

export const PopoverDiv = styled.div`
  border-radius: 10px;
  background-color: ${CSSVars.black};
  border: 1px solid #5f5f5f;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.8);

  padding: 10px 20px;
  display: flex;
  transition: 0.2s;
  position: absolute;
  top: calc(100% + 10px);
  left: -40px;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  transform: scale(0.9);

  &.apps {
    padding: 20px 30px;
  }

  &.show {
    transform: scale(1);
    pointer-events: all;
    opacity: 1;
  }

  &.light-mode {
    background-color: ${CSSVars.white};
    border: none;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

    .apps .column .title {
      color: ${CSSVars.black};
    }
  }

  a {
    min-width: 185px;
    padding: 5px 10px;
    line-height: 1.8;
    border-radius: 5px;
    transition: 0.2s;
    font-weight: 300;
    color: ${CSSVars.white};
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: ${CSSVars.fontSizeSM};

    &:hover {
      background: linear-gradient(
        269deg,
        rgba(76, 79, 255, 0) 0%,
        rgba(76, 79, 255, 0.6) 100%
      );
    }
  }

  .community {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 5px;
  }

  .company {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 5px;
  }

  .apps {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 680px;

    .column {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: ${CSSVars.fontSizeSM};
      max-width: 185px;

      &.more {
        padding-left: 20px;
        a {
          color: ${CSSVars.blue400};
          width: 155px;
          align-items: center;

          .icon {
            font-size: 15px;
          }
        }
      }

      &:nth-child(3) {
        border-right: 1px solid ${CSSVars.gray600};
      }

      .title {
        font-weight: 500;
        color: ${CSSVars.white};
        padding-left: 10px;

        font-size: ${CSSVars.fontSizeMD};
      }

      a {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        min-width: fit-content;
        cursor: pointer;

        &.widget {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        img {
          border-radius: 50%;
          margin-right: 10px;
          margin-bottom: -4px;
        }
      }
    }
  }
`;
