import { IoIosArrowForward } from "@react-icons/all-files/io/IoIosArrowForward";
import Image from "next/image";
import Link from "next/link";
import { IPlugin } from "../../../types";
import { PopoverDiv } from "./menuPopovers.styles";
import cn from "classnames";
import { support, companyLinks, features } from "@/helpers/cn-links";

interface IPopoverProps {
  columns?: { title: string; apps: IPlugin[] }[];
  show?: boolean;
  lightMode?: boolean;
}

export const AppsPopover = ({
  show,
  columns = [],
  lightMode = false,
}: IPopoverProps) => {
  return (
    <PopoverDiv className={cn("apps", { show: show, "light-mode": lightMode })}>
      <div className={`apps`}>
        {columns.map((column, idx) => (
          <div className="column" key={column.title}>
            <p className="title">{column.title}</p>
            {column.apps.map((app) => (
              <Link
                className="widget"
                title={app.name}
                key={app.slug}
                href={`/widgets/${app.slug}`}
              >
                <Image src={app.logo} alt={`${app.name} Page`} width={20} height={20} />
                {app.name}
              </Link>
            ))}
          </div>
        ))}
        <div className="column more">
          <p className="title">More</p>
          <Link href={"/widgets"}>
            See All Widgets
            <IoIosArrowForward fontSize={18} className="icon" />
          </Link>
          <Link href={"/platforms"}>
            See Platforms
            <IoIosArrowForward fontSize={18} className="icon" />
          </Link>
        </div>
      </div>
    </PopoverDiv>
  );
};

export const SupportPopover = ({
  show,
  lightMode = false,
}: IPopoverProps) => {
  return (
    <PopoverDiv className={cn({ show: show, "light-mode": lightMode })}>
      <div className="community" style={{ gridTemplateColumns: '1fr' }}>
        {support.map((link, idx) => (
          <Link
            key={link.name + link.url + idx}
            href={link.url}
            target={link.target}
            rel={link.rel}
          >
            {link.name}
          </Link>
        ))}
      </div>
    </PopoverDiv>
  );
};

export const FeaturesPopover = ({
  show,
  lightMode = false,
}: IPopoverProps) => {
  return (
    <PopoverDiv className={cn({ show: show, "light-mode": lightMode })}>
      <div className="community">
        {features.map((link, idx) => (
          <Link
            key={link.name + link.url + idx}
            href={link.url}
            target={link.target}
            rel={link.rel}
          >
            {link.name}
          </Link>
        ))}
      </div>
    </PopoverDiv>
  );
};

export const CompanyPopover = ({ show, lightMode = false }: IPopoverProps) => {
  return (
    <PopoverDiv className={cn({ show: show, "light-mode": lightMode })}>
      <div className="company">
        {companyLinks.map((link, idx) => (
          <Link
            key={link.name + link.url + idx}
            href={link.url}
            target={link.target}
            rel={link.rel}
          >
            {link.name}
          </Link>
        ))}
      </div>
    </PopoverDiv>
  );
};
