import mixpanel from 'mixpanel-browser';

mixpanel.init('49aed1723e321fc7cb57fe4b906a20f5', { debug: false });

export const trackEvent = (
	eventName: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	props: { [key: string]: any } = {}
) => {
	try {
		if (
			typeof window === 'undefined' ||
			!window.location.href.includes('www.commoninja.com')
		) {
			console.log('Skipping mixpanel report for event', eventName, props);
			return;
		}
		mixpanel.track(eventName, props);
	} catch (e) {
		console.log(e);
	}
};
