import styled, { keyframes } from "styled-components";
import { CSSVars } from "../../styles/styles.config";

export const StyledHeader = styled.header`
	align-items: center;
	height: 80px;
	padding: 0 20px;
	text-align: left;
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 99999;
	transform: translateY(-100px);
	transition: transform 0.3s ease;

	&.visible {
		transform: translateY(0);
		background: #050505de;
		margin: 10px auto;
		width: 97%;
		left: 1.5%;
		border-radius: 17px;
	}

	&:not(.in-on-top) {
		background: #050505de;
		margin: 10px auto;
		width: 97%;
		left: 1.5%;
		border-radius: 17px;
		position: fixed;
	}

	&.in-on-top {
		position: absolute;
		transform: translateY(0);
	}

	&.fix-position {
		position: relative;
		transform: translateY(0);
		margin: 0;
	}

	&.add-background {
		background: rgb(9, 10, 59);
		background: linear-gradient(
			90deg,
			rgba(9, 10, 59, 1) 0%,
			rgba(1, 1, 1, 1) 9%
		);
	}

	&.light-mode {
		background-color: ${CSSVars.white};
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
		&.in-on-top {
			box-shadow: none;
		}
		nav .links-divider {
			a,
			.link,
			.section-label {
				color: ${CSSVars.black};
			}

			.login,
			.start-creating {
				border-color: ${CSSVars.black};

				&.start-creating:hover {
					background: linear-gradient(
						269deg,
						rgba(76, 79, 255, 0) 0%,
						rgba(76, 79, 255, 0.25) 100%
					);
					border-color: ${CSSVars.blue300};
				}
			}
		}

		.company,
		.community,
		.apps {
			a:hover {
				background: linear-gradient(
					269deg,
					rgba(76, 79, 255, 0) 0%,
					rgba(76, 79, 255, 0.25) 100%
				);
			}
		}
	}

	@media screen and (min-width: ${CSSVars.breakpointMD}) {
		padding: 20px 30px;
	}

	@media screen and (min-width: 1100px) {
		display: flex;
	}

	nav {
		width: 1320px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 15px;
		
		a {
			color: ${CSSVars.white};
		}

		.links-divider {
			display: flex;
			align-items: center;

			.links {
				align-items: center;
				gap: 30px;
				margin-left: 30px;
				display: flex;

				.link-wrapper {
					position: relative;

					&::before {
						content: '';
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;
						height: 15px;
					}
				}
			}

			&.right-side {
				gap: 30px;
			}

			// main links style
			a,
			.link,
			.section-label {
				color: ${CSSVars.white};
				font-weight: 300;
				transition: 0.2s;

				&.section-label {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 5px;

					&:hover,
					&.show {
						color: ${CSSVars.blue400};
					}
				}

				&.login {
					position: relative;
					font-weight: 400;
					padding-bottom: 1px;

					&::before {
						content: '';
						position: absolute;
						top: calc(100% + 2px);
						left: 0;
						width: 0;
						height: 1px;
						border-radius: 5px;
						background-color: ${CSSVars.blue400};
						transition: 0.3s;
					}

					&:hover {
						color: ${CSSVars.blue400};

						&::before {
							width: 100%;
						}
					}
				}

				&.start-creating {
					border-radius: 8px;
					padding: 5px 20px;
					border: 1px solid ${CSSVars.white};
					transition: all 0.2s;

					&:hover {
						background: linear-gradient(
							269deg,
							rgba(76, 79, 255, 0) 0%,
							rgba(76, 79, 255, 0.6) 100%
						);
						border-color: ${CSSVars.blue400};
					}
				}

				&.logo {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
`;

export const StyledMiniHeader = styled.header`
	@media screen and (min-width: 1100px) {
		display: none;
	}

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 99999;
	transform: translateY(-100px);
	transition: transform 0.3s ease;

	&.visible {
		transform: translateY(0);
		background: #050505de;
		margin: 10px auto;
		width: 97%;
		left: 1.5%;
		border-radius: 17px;
	}

	&:not(.in-on-top) {
		background: #050505de;
		margin: 10px auto;
		width: 97%;
		left: 1.5%;
		border-radius: 17px;
		position: fixed;
	}

	&.add-background {
		background: rgb(9, 10, 59);
		background: linear-gradient(
			90deg,
			rgba(9, 10, 59, 1) 0%,
			rgba(1, 1, 1, 1) 9%
		);
	}

	&.in-on-top {
		position: absolute;
		top: 100px;
		transform: translateY(-100px);
		transition-duration: 0.05s;
	}

	&.fix-position {
		position: relative;
		transform: translateY(0);
		margin: 0;
	}

	nav {
		margin: 0 auto;
		height: 60px;
		padding: 0 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1580px;

		.logo {
			display: flex;
		}

		.hamburger {
			cursor: pointer;
			color: ${CSSVars.gray600};
			transition: 0.2s;
		}
	}
`;

export const StyledAside = styled.aside`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 270px;
  z-index: 99999;
  background-color: ${CSSVars.black};
  padding: 25px;

  transition: 0.2s;
  transform: translateX(-270px);

  &.opened {
    transform: translateX(0);

    @media screen and (min-width: 1100px) {
      transform: translateX(-270px);
    }
  }

  text-align: left;
  display: grid;
  grid-template-rows: 50px 1fr max-content;
  grid-template-areas:
    "top"
    "content"
    "bottom";

  .top {
    grid-area: top;
    height: 100px;
  }
  .content {
    grid-area: content;
    width: 100%;
    overflow: auto;

    // opened links
    nav {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 100%;

      a {
        padding: 5px 10px;
        border-radius: 5px;
        transition: 0.2s;
        color: ${CSSVars.white};

        &.active,
        &:hover {
          background: linear-gradient(
            269deg,
            rgba(76, 79, 255, 0) 0%,
            rgba(76, 79, 255, 0.6) 100%
          );
        }
      }

      .sub-items {
        .title {
          padding-left: 10px;
          margin: 15px 0 5px;
          font-size: ${CSSVars.fontSizeXS};
          color: ${CSSVars.gray400};
          font-weight: 500;
        }

        .items {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  .bottom {
    grid-area: bottom;
    display: flex;
    flex-direction: column;
    gap: 20px;

    padding: 20px 0;
    a {
      color: white;
    }

    .start-creating {
      border-radius: 8px;
      padding: 5px 20px;
      width: max-content;
      border: 1px solid ${CSSVars.white};

      &:hover {
        background: linear-gradient(
          269deg,
          rgba(76, 79, 255, 0) 0%,
          rgba(76, 79, 255, 0.6) 100%
        );
      }
    }
  }

  img {
    cursor: pointer;
  }
`;
